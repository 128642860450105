import {
  DataGrid,
  GridRenderCellParams,
  GridColDef,
  esES,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridRowModel,
} from "@mui/x-data-grid";
import {
  Alert,
  AlertProps,
  Button,
  Card,
  Snackbar,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/storeHooks";
import {
  downloadExcel,
  fetchTrips,
  updateTrip,
} from "../../../services/tripService";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";
import DocumentsModal from "./DocumentsModal";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { fetchDrivers } from "../../../services/driverService";
import { fetchVehicles } from "../../../services/vehicleService";
import { filterTripsForView } from "../../../utils/filterTrips";

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
};
const TripsView: React.FC = () => {
  const { isLoading, trips } = useAppSelector(
    (state: any) => state.tripReducer
  );

  const [modalOpen, setModalOpen] = useState(false); // para abrir los documentos

  const isLoadingDrivers = useAppSelector((s) => s.driverReducer.isLoading);
  const [selectedPhotos, setSelectedPhotos] = useState<string[] | null>(null);
  const [fromDate, setFromDate] = useState<Date | null>(new Date(Date.now()));
  const [toDate, setToDate] = useState<Date | null>(new Date(Date.now()));
  const [filteredTrips, setFilteredTrips] = useState<Trip[]>([]);

  const filterTrips = () => {
    const filteredTrips = filterTripsForView(trips, fromDate!, toDate!);
    setFilteredTrips(filteredTrips);
  };

  const columns: GridColDef[] = [
    {
      field: "driver",
      minWidth: 250,
      flex: 1,
      headerName: "Nombre Conductor",
      valueGetter(params) {
        if (
          typeof params.row.driver !== "undefined" &&
          params.row.driver !== null
        ) {
          return params.row.driver.name;
        } else {
          return "Conductor Eliminado";
        }
      },
      valueFormatter(params) {
        return params.value;
      },

      renderCell: (params: GridRenderCellParams) => {
        if (params.row.driver === null) {
          return <span>Conductor eliminado</span>;
        } else {
          return <span>{params.row.driver.name}</span>;
        }
      },
    },
    {
      field: "origin",
      minWidth: 150,
      flex: 1,
      headerName: "Origen",
      editable: true,
    },
    {
      field: "destination",
      minWidth: 150,
      flex: 1,
      headerName: "Destino",
      editable: true,
    },
    { field: "vehiclePlate", minWidth: 150, flex: 1, headerName: "Patente" },
    {
      field: "programmedAt",
      minWidth: 150,
      flex: 1,
      headerName: "Fecha Programada",
      type: "dateTime",
      valueFormatter(params) {
        const date = new Date(params.value);
        return `${date.getDate()}/${
          date.getMonth() + 1
        }/${date.getFullYear()} `;
      },
      renderCell: (params: GridRenderCellParams) => {
        const date = new Date(params.row.programmedAt);
        return (
          <span>{`${date.getDate()}/${
            date.getMonth() + 1
          }/${date.getFullYear()} `}</span>
        );
      },
    },
    {
      field: "programmedHourAt",
      minWidth: 150,
      flex: 1,
      headerName: "Hora Programada",
      valueGetter(params) {
        const date = new Date(params.row.programmedAt);
        const minutes = date.getMinutes();
        const hours = date.getHours();
        return `${hours}:${minutes.toString().padStart(2, "0")}`;
      },

      renderCell: (params: GridRenderCellParams) => {
        const date = new Date(params.row.programmedAt);
        const minutes = date.getMinutes();

        return (
          <span>{`${date.getHours()}:${minutes
            .toString()
            .padStart(2, "0")}`}</span>
        );
      },
    },

    {
      field: "finishedAt",
      minWidth: 150,
      flex: 1,
      headerName: "Hora Fin",
      valueFormatter(params) {
        if (typeof params.value === "undefined") {
          return "";
        } else {
          const date = new Date(params.value);
          const minutes = date.getMinutes();

          const hours = date.getHours();
          return `${hours}:${minutes.toString().padStart(2, "0")}`;
        }
      },
      renderCell: (params: GridRenderCellParams) => {
        const date = new Date(params.row.finishedAt);
        const minutes = date.getMinutes();

        const hours = date.getHours();
        if (typeof params.row.finishedAt === "undefined") {
          return <span></span>;
        } else
          return (
            <span>{`${hours}:${minutes.toString().padStart(2, "0")}`}</span>
          );
      },
    },
    {
      field: "status",
      minWidth: 150,
      flex: 1,
      headerName: "Estado",

      renderCell: (params: GridRenderCellParams) => {
        if (params.row.status === "Pendiente") {
          return <span style={{ color: "#ED6E11" }}>Pendiente</span>;
        } else return <span className="text-green-500">Completado</span>;
      },
    },
    {
      field: "customer",
      minWidth: 150,
      flex: 1,
      headerName: "Cliente",
      valueGetter(params) {
        if (typeof params.row.customer !== "undefined") {
          if (params.row.customer !== null) {
            return params.row.customer.name;
          }
        } else {
          return "";
        }
      },
      valueFormatter(params) {
        return params.value;
      },
    },

    {
      field: "document",
      minWidth: 150,
      flex: 1,
      headerName: "Documento",
      renderCell: (params: GridRenderCellParams) => {
        if (
          typeof params.row.photos !== "undefined" &&
          params.row.photos.length > 0
        ) {
          return (
            <Button
              variant="contained"
              onClick={() => {
                setSelectedPhotos(params.row.photos);
                setModalOpen(true);
              }}
            >
              Abrir
            </Button>
          );
        } else return <span></span>;
      },
    },
  ];

  useEffect(() => {
    fetchDrivers();
    fetchTrips();
    fetchVehicles();
  }, []);
  useEffect(() => setFilteredTrips(trips), [trips]);

  return (
    <div className="p-5 h-full overflow-auto">
      <div className="text-xl mb-5">Trayectos </div>
      <div className="flex justify-end gap-2 m-3">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="Desde"
            inputFormat="dd/MM/yyyy"
            value={fromDate}
            onChange={(newValue: Date | null) => {
              setFromDate(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="Hasta"
            inputFormat="dd/MM/yyyy"
            value={toDate}
            onChange={(newValue: Date | null) => {
              setToDate(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <Button
          variant="contained"
          disabled={!fromDate || !toDate}
          color="primary"
          onClick={() => {
            filterTrips();
          }}
        >
          Filtrar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            downloadExcel();
          }}
        >
          Descargar
        </Button>
      </div>
      <div>
        {isLoading || isLoadingDrivers ? (
          <div className="flex justify-center w-full col-span-full">
            <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
          </div>
        ) : (
          <Card>
            <DataGrid
              sx={{ padding: 2 }}
              autoHeight={true}
              columns={columns}
              rows={filteredTrips}
              pageSize={20}
              getRowId={(row) => row._id}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              components={{ Toolbar: CustomToolbar }}
            />
          </Card>
        )}
      </div>

      <DocumentsModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        photos={selectedPhotos}
      />
    </div>
  );
};
export default TripsView;
