import {
  DataGrid,
  GridRenderCellParams,
  GridColDef,
  esES,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Button, Card } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/storeHooks";
import { fetchVehicles, deleteVehicle } from "../../../services/vehicleService";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";
import NewVehicleModal from "./NewVehicleModal";
import { fetchDrivers } from "../../../services/driverService";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

const VehiclesView: React.FC = () => {
  const { isLoading, vehicles } = useAppSelector(
    (state) => state.vehicleReducer
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null);

  const columns: GridColDef[] = [
    { field: "plate", minWidth: 150, flex: 1, headerName: "Patente" },
    {
      field: "name",
      minWidth: 150,
      flex: 1,
      headerName: "Nombre Conductor",
      renderCell: (params: GridRenderCellParams) => (
        <div>
          {!!params.row.driver ? (
            <div>{params.row.driver.name}</div>
          ) : (
            <div>Sin Conductor</div>
          )}
        </div>
      ),
    },
    {
      field: "edit",
      minWidth: 100,
      flex: 1,
      headerName: "Editar",
      renderCell: (params: GridRenderCellParams) => (
        <Button
          sx={{ backgroundColor: "blue", color: "white" }}
          onClick={() => {
            for (let i = 0; i < vehicles.length; i++) {
              if (vehicles[i]._id === params.row._id) {
                setSelectedVehicle(vehicles[i]);
                setModalOpen(true);
              }
            }
          }}
        >
          {" "}
          Editar
        </Button>
      ),
    },
    {
      field: "delete",
      minWidth: 150,
      flex: 1,
      headerName: "Borrar",
      renderCell: (params: GridRenderCellParams) => (
        <Button
          sx={{ backgroundColor: "red", color: "white" }}
          onClick={() => {
            deleteVehicle(params.row._id);
          }}
        >
          Borrar
        </Button>
      ),
    },
  ];
  useEffect(() => {
    fetchVehicles();
    fetchDrivers();
  }, []);

  return (
    <div className="p-5 h-full overflow-auto">
      <div className="text-xl mb-5">Vehiculos</div>
      <div className="mb-3 flex justify-end pr-7">
        <Button variant="contained" onClick={() => setModalOpen(true)}>
          {" "}
          Agregar
        </Button>
      </div>
      <div>
        {isLoading ? (
          <div className="flex justify-center w-full col-span-full">
            <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
          </div>
        ) : (
          <Card>
            <DataGrid
              sx={{ padding: 2 }}
              autoHeight={true}
              columns={columns}
              rows={vehicles}
              pageSize={20}
              getRowId={(row) => row._id}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              components={{ Toolbar: CustomToolbar }}
            />
            <NewVehicleModal
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              vehicle={selectedVehicle}
              setVehicle={setSelectedVehicle}
            />
          </Card>
        )}
      </div>
    </div>
  );
};

export default VehiclesView;
