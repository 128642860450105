import axios from "./client";
import { CustomerActions } from "../reducers/CustomerReducer";
import store from "./../store";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchCustomers = async () => {
  try {
    store.dispatch({ type: CustomerActions.RequestCustomers, payload: {} });
    const { data: customers } = await axios.get<Customer[]>(
      `${API_URL}customers`
    );
    store.dispatch({
      type: CustomerActions.ReceiveCustomers,
      payload: customers,
    });
  } catch (error) {
    console.log(error);
  }
};
export const deleteCustomer = async (id: string) => {
  const { data: customer } = await axios.delete<Customer>(
    `${API_URL}customers/${id}`
  );
  store.dispatch({
    type: CustomerActions.DeleteCustomer,
    payload: customer,
  });
};
export const updateCustomer = async (
  id: string,
  customer: Partial<Customer>
) => {
  const { data: editedCustomer } = await axios.patch<Customer>(
    `${API_URL}customers/${id}`,
    customer
  );
  store.dispatch({
    type: CustomerActions.AddCustomer,
    payload: editedCustomer,
  });
};
export const addCustomer = async (customer: Partial<Customer>) => {
  const { data: newCustomer } = await axios.post<Customer>(
    `${API_URL}customers/`,
    customer
  );
  store.dispatch({
    type: CustomerActions.AddCustomer,
    payload: newCustomer,
  });
};
