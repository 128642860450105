import {
  DataGrid,
  GridRenderCellParams,
  GridColDef,
  esES,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Button, Card } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/storeHooks";
import {
  fetchCustomers,
  deleteCustomer,
} from "../../../services/customerService";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";
import NewCustomerModal from "./NewCustomerModal";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

const CustomersView: React.FC = () => {
  const { isLoading, customers } = useAppSelector(
    (state: any) => state.customerReducer
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(
    null
  );

  useEffect(() => {
    fetchCustomers();
  }, []);
  const columns: GridColDef[] = [
    {
      field: "name",
      minWidth: 150,
      flex: 1,
      headerName: "Nombre",
    },
    { field: "rut", minWidth: 150, flex: 1, headerName: "Rut" },
    {
      field: "edit",
      minWidth: 100,
      flex: 1,
      headerName: "Editar",
      renderCell: (params: GridRenderCellParams) => (
        <Button
          sx={{ backgroundColor: "blue", color: "white" }}
          onClick={() => {
            for (let i = 0; i < customers.length; i++) {
              if (customers[i]._id === params.row._id) {
                setSelectedCustomer(customers[i]);
                setModalOpen(true);
              }
            }
          }}
        >
          Editar
        </Button>
      ),
    },
    {
      field: "delete",
      minWidth: 100,
      flex: 1,
      headerName: "Eliminar",
      renderCell: (params: GridRenderCellParams) => (
        <Button
          sx={{ backgroundColor: "red", color: "white" }}
          onClick={() => {
            deleteCustomer(params.row._id);
          }}
        >
          Eliminar
        </Button>
      ),
    },
  ];

  return (
    <div className="p-5 h-full overflow-auto">
      <div className="text-xl mb-5">Clientes</div>
      <div className="mb-3 flex justify-end pr-7">
        <Button variant="contained" onClick={() => setModalOpen(true)}>
          {" "}
          Agregar
        </Button>
      </div>
      <div>
        {isLoading ? (
          <div className="flex justify-center w-full col-span-full">
            <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
          </div>
        ) : (
          <Card>
            <DataGrid
              sx={{ padding: 2 }}
              autoHeight={true}
              columns={columns}
              rows={customers}
              pageSize={20}
              getRowId={(row) => row._id}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              components={{ Toolbar: CustomToolbar }}
            />
            <NewCustomerModal
              open={modalOpen}
              customer={selectedCustomer}
              setCustomer={setSelectedCustomer}
              onClose={() => setModalOpen(false)}
            />
          </Card>
        )}
      </div>
    </div>
  );
};
export default CustomersView;
