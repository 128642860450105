import {
  DataGrid,
  GridRenderCellParams,
  GridColDef,
  esES,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Button, Card } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/storeHooks";
import { fetchDrivers, deleteDriver } from "../../../services/driverService";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";
import NewDriverModal from "./NewDriverModal";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

const DriversView: React.FC = () => {
  const { isLoading, drivers } = useAppSelector(
    (state: any) => state.driverReducer
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState<Driver | null>(null);

  const columns: GridColDef[] = [
    { field: "name", minWidth: 150, flex: 1, headerName: "Nombre" },
    {
      field: "rut",
      minWidth: 150,
      flex: 1,
      headerName: "Rut",
    },
    {
      field: "edit",
      minWidth: 100,
      flex: 1,
      headerName: "Editar",
      renderCell: (params: GridRenderCellParams) => (
        <Button
          sx={{ backgroundColor: "blue", color: "white" }}
          onClick={() => {
            for (let i = 0; i < drivers.length; i++) {
              if (drivers[i]._id === params.row._id) {
                setSelectedDriver(drivers[i]);
                setModalOpen(true);
              }
            }
          }}
        >
          Editar
        </Button>
      ),
    },
    {
      field: "delete",
      minWidth: 100,
      flex: 1,
      headerName: "Borrar",
      renderCell: (params: GridRenderCellParams) => (
        <Button
          sx={{ backgroundColor: "red", color: "white" }}
          onClick={() => {
            deleteDriver(params.row._id);
          }}
        >
          Borrar
        </Button>
      ),
    },
  ];
  useEffect(() => {
    fetchDrivers();
  }, []);

  return (
    <div className="p-5 h-full overflow-auto">
      <div className="text-xl mb-5">Conductores</div>
      <div className="mb-3 flex justify-end pr-7">
        <Button variant="contained" onClick={() => setModalOpen(true)}>
          {" "}
          Agregar
        </Button>
      </div>
      <div>
        {isLoading ? (
          <div className="flex justify-center w-full col-span-full">
            <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
          </div>
        ) : (
          <Card>
            <DataGrid
              sx={{ padding: 2 }}
              autoHeight={true}
              columns={columns}
              rows={drivers}
              pageSize={20}
              getRowId={(row) => row._id}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              components={{ Toolbar: CustomToolbar }}
            />
            <NewDriverModal
              open={modalOpen}
              driver={selectedDriver}
              setDriver={setSelectedDriver}
              onClose={() => setModalOpen(false)}
            />
          </Card>
        )}
      </div>
    </div>
  );
};
export default DriversView;
