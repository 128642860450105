import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { Routes } from "../config/Routes";
import { mdiLogoutVariant } from "@mdi/js";
import Icon from "@mdi/react";
import { AuthActions } from "../reducers/AuthReducer";
import store from "../store";
import { useState } from "react";
import { useAppSelector } from "../hooks/storeHooks";

const Menu: React.FC = () => {
  const history = useLocation();
  const user = useAppSelector((s) => s.authReducer.user);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const route = history.pathname;

  return (
    <div>
      <section className=" flex lg:hidden bg-archiBlue">
        <div
          className="space-y-2 p-7"
          onClick={() => setIsNavOpen((prev) => !prev)} // toggle isNavOpen state on click
        >
          <span className="block h-1 w-8 animate-pulse bg-archiGrey"></span>
          <span className="block h-1 w-8 animate-pulse bg-archiGrey"></span>
          <span className="block h-1 w-8 animate-pulse bg-archiGrey"></span>
        </div>

        <div className={isNavOpen ? "showMenuNav" : "hideMenuNav"}>
          <div
            className="CROSS-ICON absolute top-0 right-0 px-8 py-8"
            onClick={() => setIsNavOpen(false)}
          >
            <svg
              className="h-8 w-8 text-gray-600"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          </div>
          <div className=" flex flex-col items-center justify-between min-h-[250px]">
            <Link to={Routes.Home}>
              <MenuItem>
                <div
                  onClick={() => setIsNavOpen(false)}
                  className={`${
                    route === Routes.Home ? "text-archiLightBlue" : ""
                  }`}
                >
                  Inicio
                </div>
              </MenuItem>
            </Link>

            <Link to={Routes.NewTrip}>
              <MenuItem>
                <div
                  onClick={() => setIsNavOpen(false)}
                  className={`${
                    route === Routes.NewTrip ? "text-archiLightBlue" : ""
                  }`}
                >
                  Nuevo Trayecto
                </div>
              </MenuItem>
            </Link>

            <Link to={Routes.Trips}>
              <MenuItem>
                <div
                  onClick={() => setIsNavOpen(false)}
                  className={`${
                    route === Routes.Trips ? "text-archiLightBlue" : ""
                  }`}
                >
                  Trayectos
                </div>
              </MenuItem>
            </Link>

            <Link to={Routes.Drivers}>
              <MenuItem>
                <div
                  onClick={() => setIsNavOpen(false)}
                  className={`${
                    route === Routes.Drivers ? "text-archiLightBlue" : ""
                  }`}
                >
                  Conductores
                </div>
              </MenuItem>
            </Link>

            <Link to={Routes.Vehicles}>
              <MenuItem>
                <div
                  onClick={() => setIsNavOpen(false)}
                  className={`${
                    route === Routes.Vehicles ? "text-archiLightBlue" : ""
                  }`}
                >
                  Vehiculos
                </div>
              </MenuItem>
            </Link>

            <Link to={Routes.Customers}>
              <MenuItem>
                <div
                  onClick={() => setIsNavOpen(false)}
                  className={`${
                    route === Routes.Customers ? "text-archiLightBlue" : ""
                  }`}
                >
                  Clientes
                </div>
              </MenuItem>
            </Link>
            <MenuItem
              onClick={() =>
                store.dispatch({ type: AuthActions.DelToken, payload: null })
              }
            >
              <ListItemText>Cerrar sesión</ListItemText>
              <ListItemIcon sx={{ color: "inherit" }}>
                <Icon className="w-7" path={mdiLogoutVariant}></Icon>
              </ListItemIcon>
            </MenuItem>
          </div>
        </div>
      </section>
      <div
        className="hidden md:flex md:w-72 h-full  gap-3 text-xl overflow-y-auto flex-col bg-archiBlue text-archiGrey"
        style={{ flex: "0 0 200px" }}
      >
        <Link to={Routes.Home}>
          <MenuItem>
            <div
              className={`${
                route === Routes.Home ? "text-archiLightBlue" : ""
              }`}
            >
              Inicio
            </div>
          </MenuItem>
        </Link>

        <Link to={Routes.NewTrip}>
          <MenuItem>
            <div
              className={`${
                route === Routes.NewTrip ? "text-archiLightBlue" : ""
              }`}
            >
              Nuevo Trayecto
            </div>
          </MenuItem>
        </Link>

        <Link to={Routes.Trips}>
          <MenuItem>
            <div
              className={`${
                route === Routes.Trips ? "text-archiLightBlue" : ""
              }`}
            >
              Trayectos
            </div>
          </MenuItem>
        </Link>

        <Link to={Routes.Drivers}>
          <MenuItem>
            <div
              className={`${
                route === Routes.Drivers ? "text-archiLightBlue" : ""
              }`}
            >
              Conductores
            </div>
          </MenuItem>
        </Link>

        <Link to={Routes.Vehicles}>
          <MenuItem>
            <div
              className={`${
                route === Routes.Vehicles ? "text-archiLightBlue" : ""
              }`}
            >
              Vehiculos
            </div>
          </MenuItem>
        </Link>

        <Link to={Routes.Customers}>
          <MenuItem>
            <div
              className={`${
                route === Routes.Customers ? "text-archiLightBlue" : ""
              }`}
            >
              Clientes
            </div>
          </MenuItem>
        </Link>

        <MenuItem
          onClick={() => {
            store.dispatch({ type: AuthActions.SetStoreData, payload: null });
            store.dispatch({ type: AuthActions.DelToken, payload: null });
          }}
        >
          <ListItemText>Cerrar sesión</ListItemText>
          <ListItemIcon sx={{ color: "inherit" }}>
            <Icon className="w-7" path={mdiLogoutVariant}></Icon>
          </ListItemIcon>
        </MenuItem>
      </div>
      <style>{`
      .hideMenuNav {
        display: none;
      }
      .showMenuNav {
        display: block;
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        background: white;
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
      }
    `}</style>
    </div>
  );
};

export default Menu;
