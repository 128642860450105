export const filterTripsForView = (
  trips: Trip[],
  fromDate: Date,
  toDate: Date
) => {
  fromDate!.setHours(0, 0, 0, 0);
  toDate!.setHours(23, 59, 59, 999);
  const filteredTripsToSend = trips.filter((trip) => {
    const time = new Date(trip.programmedAt!).getTime();
    const sd = fromDate!.getTime();
    const ed = toDate!.getTime();
    return sd <= time && time <= ed;
  });
  return filteredTripsToSend;
};
