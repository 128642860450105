import axios from "./client";
import { VehicleActions } from "../reducers/VehicleReducer";
import store from "./../store";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchVehicles = async () => {
  try {
    store.dispatch({ type: VehicleActions.RequestVehicles, payload: {} });
    const { data: vehicles } = await axios.get<Vehicle[]>(`${API_URL}vehicles`);
    store.dispatch({
      type: VehicleActions.ReceiveVehicles,
      payload: vehicles,
    });
  } catch (error) {
    console.log(error);
  }
};
export const deleteVehicle = async (id: string) => {
  const { data: vehicle } = await axios.delete<Vehicle>(
    `${API_URL}vehicles/${id}`
  );
  store.dispatch({
    type: VehicleActions.DeleteVehicle,
    payload: vehicle,
  });
};
export const updateVehicle = async (id: string, vehicle: Partial<Vehicle>) => {
  const { data: editedVehicle } = await axios.patch<Vehicle>(
    `${API_URL}vehicles/${id}`,
    vehicle
  );
  store.dispatch({
    type: VehicleActions.AddVehicle,
    payload: editedVehicle,
  });
};
export const addVehicle = async (vehicle: Partial<Vehicle>) => {
  const { data: newVehicle } = await axios.post<Vehicle>(
    `${API_URL}vehicles/`,
    vehicle
  );
  store.dispatch({
    type: VehicleActions.AddVehicle,
    payload: newVehicle,
  });
};
