import { combineReducers } from "redux";
import authReducer from "./AuthReducer";
import driverReducer from "./DriverReducer";
import vehicleReducer from "./VehicleReducer";
import customerReducer from "./CustomerReducer";
import tripReducer from "./TripReducer";

const rootReducer = combineReducers({
  authReducer,
  driverReducer,
  vehicleReducer,
  customerReducer,
  tripReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
