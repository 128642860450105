import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/storeHooks";
import { useForm } from "../../../hooks/useForm";
import { addVehicle, updateVehicle } from "../../../services/vehicleService";

interface Props {
  open: boolean;
  onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
  vehicle: Vehicle | null;
  setVehicle: (vehicle: Vehicle | null) => void;
}

const initialValues = {
  plate: "",
};

const NewVehicleModal: React.FC<Props> = ({
  open,
  onClose,
  vehicle,
  setVehicle,
}) => {
  const [values, setValues, handleChange] = useForm(initialValues);
  const drivers = useAppSelector((s) => s.driverReducer.drivers);
  const [selectedDriver, setSelectedDriver] = useState<string>("");

  useEffect(() => {
    if (vehicle !== null) {
      setValues({ ...values, plate: vehicle.plate });
      if (vehicle.driver !== undefined) {
        drivers.forEach((driver) => {
          if (driver.rut === vehicle.driver.rut) {
            setSelectedDriver(driver._id);
          }
        });
      }
    }
  }, [vehicle]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onTransitionEnd={() => !open && setValues(initialValues)}
    >
      <DialogTitle>Nuevo Vehiculo</DialogTitle>
      <DialogContent>
        <div className="flex flex-col gap-2 mt-5">
          <TextField
            onChange={handleChange}
            id="plate"
            value={values.plate}
            label="Patente"
          ></TextField>

          <FormControl fullWidth>
            <InputLabel id="driver-label">Conductor</InputLabel>
            <Select
              labelId="driver-label"
              id="driver"
              value={selectedDriver}
              label="Conductor"
              onChange={(e) => {
                setSelectedDriver(e.target.value as string);
              }}
            >
              <MenuItem value={"Sin Conductor"}>Sin Conductor </MenuItem>
              {drivers.map((driver) => (
                <MenuItem key={driver._id} value={driver._id}>
                  {driver.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (vehicle !== null) {
              setVehicle(null);
              setValues(initialValues);
            }
            onClose();
          }}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          disabled={values.plate === ""}
          onClick={async () => {
            var driverToSend = undefined;
            if (selectedDriver !== "Sin Conductor") {
              driverToSend = drivers.find(
                (driver) => driver._id === selectedDriver
              );
            }

            if (vehicle !== null) {
              await updateVehicle(vehicle._id, {
                ...values,
                driver: driverToSend,
              });
              setVehicle(null);
            } else {
              await addVehicle({ ...values, driver: driverToSend });
            }

            onClose();
          }}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewVehicleModal;
