import axios from "./client";
import { DriverActions } from "../reducers/DriverReducer";
import store from "./../store";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchDrivers = async () => {
  try {
    store.dispatch({ type: DriverActions.RequestDrivers, payload: {} });
    const { data: drivers } = await axios.get<Driver[]>(`${API_URL}drivers`);
    store.dispatch({
      type: DriverActions.ReceiveDrivers,
      payload: drivers,
    });
  } catch (error) {
    console.log(error);
  }
};

export const deleteDriver = async (id: string) => {
  const { data: driver } = await axios.delete<Driver>(
    `${API_URL}drivers/${id}`
  );
  store.dispatch({
    type: DriverActions.DeleteDriver,
    payload: driver,
  });
};
export const updateDriver = async (id: string, driver: Partial<Driver>) => {
  const { data: editedDriver } = await axios.patch<Driver>(
    `${API_URL}drivers/${id}`,
    driver
  );
  store.dispatch({
    type: DriverActions.AddDriver,
    payload: editedDriver,
  });
};
export const addDriver = async (driver: Partial<Driver>) => {
  const { data: newDriver } = await axios.post<Driver>(
    `${API_URL}drivers/`,
    driver
  );
  store.dispatch({
    type: DriverActions.AddDriver,
    payload: newDriver,
  });
};
