import { Alert, Button, Card, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/storeHooks";
import { addTrip } from "../../../services/tripService";

import { TextField, FormControl, InputLabel, MenuItem } from "@mui/material";

import { useForm } from "../../../hooks/useForm";
import { fetchDrivers } from "../../../services/driverService";
import { fetchVehicles } from "../../../services/vehicleService";
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { fetchCustomers } from "../../../services/customerService";

const NewTrip: React.FC = () => {
  const drivers = useAppSelector((s) => s.driverReducer.drivers);
  const vehicles = useAppSelector((s) => s.vehicleReducer.vehicles);
  const customers = useAppSelector((s) => s.customerReducer.customers);

  const [tripCreated, setTripCreated] = useState(false);
  const initialValues = {
    origin: "",
    destination: "",
    driver: "",
    vehiclePlate: "",
    customer: "",
  };
  const cleanState = () => {
    setTripCreated(false);
    setValues(initialValues);
  };
  const [date, setDate] = useState<Date | null>(new Date(Date.now()));

  const handleChangeDate = (newValue: Date | null) => {
    setDate(newValue);
  };

  const [values, setValues, handleChange] = useForm(initialValues);
  useEffect(() => {
    fetchDrivers();
    fetchVehicles();
    fetchCustomers();
  }, []);
  useEffect(() => {
    if (tripCreated === true) {
      setTimeout(cleanState, 1500);
    }
  }, [tripCreated]);
  return (
    <div className="p-5 h-full overflow-auto">
      <div className="text-xl mb-5">Nuevo Trayecto </div>

      <div className="max-w-xl">
        {tripCreated && (
          <Alert
            className="mb-5 q"
            severity="success"
            onClose={() => {
              setTripCreated(false);
            }}
          >
            <strong>Trayecto Creado Correctamente</strong>
          </Alert>
        )}

        <Card>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className="flex flex-col gap-3 m-5">
              <FormControl fullWidth>
                <TextField
                  onChange={handleChange}
                  id="origin"
                  label="Origen"
                  value={values.origin}
                ></TextField>
              </FormControl>

              <FormControl fullWidth>
                <TextField
                  onChange={handleChange}
                  id="destination"
                  label="Destino"
                  value={values.destination}
                ></TextField>
              </FormControl>

              <FormControl fullWidth>
                <DesktopDatePicker
                  label="Fecha del Trayecto"
                  inputFormat="dd/MM/yyyy"
                  value={date}
                  onChange={handleChangeDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              </FormControl>
              <FormControl fullWidth>
                <TimePicker
                  label="Hora del Trayecto"
                  value={date}
                  onChange={handleChangeDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="vehicle-label">Vehiculo</InputLabel>
                <Select
                  labelId="vehicle-label"
                  id="vehicle"
                  value={values.vehiclePlate}
                  renderValue={(selectedVehicle) => selectedVehicle}
                  label="Vehiculo"
                  onChange={(e) => {
                    vehicles.forEach((v) => {
                      if (v._id === e.target.value) {
                        if (v.driver !== undefined) {
                          setValues({
                            ...values,
                            vehiclePlate: v.plate,
                            driver: v.driver._id,
                          });
                        } else
                          setValues({
                            ...values,
                            vehiclePlate: v.plate,
                            driver: "",
                          });
                      }
                    });
                  }}
                >
                  {vehicles.map((vehicle) => (
                    <MenuItem key={vehicle._id} value={vehicle._id}>
                      {vehicle.plate}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="driver-label">Conductor</InputLabel>
                <Select
                  labelId="driver-label"
                  id="driver"
                  value={values.driver}
                  label="Conductor"
                  onChange={(e) => {
                    var vehicleToSend = "";
                    vehicles.forEach((v) => {
                      if (v.driver !== null && v.driver !== undefined) {
                        console.log(v.driver);
                        if (v.driver._id === e.target.value) {
                          vehicleToSend = v.plate;
                        }
                      }
                    });
                    if (vehicleToSend !== "") {
                      console.log(vehicleToSend);
                      setValues({
                        ...values,
                        driver: e.target.value,
                        vehiclePlate: vehicleToSend,
                      });
                    } else {
                      setValues({ ...values, driver: e.target.value });
                    }
                  }}
                >
                  {drivers.map((driver) => (
                    <MenuItem key={driver._id} value={driver._id}>
                      {driver.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="customer-label">Cliente</InputLabel>
                <Select
                  labelId="customer-label"
                  id="customer"
                  value={values.customer}
                  label="Cliente"
                  onChange={(e) => {
                    setValues({ ...values, customer: e.target.value });
                  }}
                >
                  {customers.map((customer) => (
                    <MenuItem key={customer._id} value={customer._id}>
                      {customer.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Button
                variant={"contained"}
                disabled={
                  values.origin === "" ||
                  values.destination === "" ||
                  values.vehiclePlate === "" ||
                  values.driver === "" ||
                  values.customer === ""
                }
                onClick={async () => {
                  setTripCreated(true);
                  await addTrip({ ...values, programmedAt: date! });
                }}
              >
                Crear
              </Button>
            </div>
          </LocalizationProvider>
        </Card>
      </div>
    </div>
  );
};
export default NewTrip;
