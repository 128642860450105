import axios from "./client";
import { TripActions } from "../reducers/TripReducer";
import store from "./../store";
import FileDownload from "js-file-download";

const API_URL = process.env.REACT_APP_API_URL;

export const fetchTrips = async () => {
  try {
    store.dispatch({ type: TripActions.RequestTrips, payload: {} });
    const { data: trips } = await axios.get<Trip[]>(`${API_URL}trips`);
    store.dispatch({
      type: TripActions.ReceiveTrips,
      payload: trips,
    });
  } catch (error) {
    console.log(error);
  }
};
export const addTrip = async (trip: Partial<Trip>) => {
  try {
    const { data: newTrip } = await axios.post<Trip>(`${API_URL}trips`, trip);
    store.dispatch({
      type: TripActions.AddTrip,
      payload: newTrip,
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateTrip = async (id: string, trip: Partial<Trip>) => {
  try {
    const { data: editedTrip } = await axios.patch<Trip>(
      `${API_URL}trips/${id}`,
      trip
    );
    store.dispatch({
      type: TripActions.AddTrip,
      payload: editedTrip,
    });
    return editedTrip;
  } catch (error) {
    console.log(error);
  }
};

export const downloadExcel = async () => {
  try {
    const { data: excel } = await axios.get(`${API_URL}trips/xls`, {
      responseType: "blob",
      headers: {
        "content-type": "application/vnd.ms-excel;charset=UTF-8",
      },
    });
    FileDownload(excel, "viajes.xlsx");
  } catch (error) {
    console.log(error);
  }
};
